<script lang="ts" setup>
import type { WindiColor } from '@cypress-design/vue-icon'
import useMounted from '@/utilities/useMounted'
import TestingTypeIcon from './TestingTypeIcon'
import { setTestingTypeFromHash, testingType } from '../stores'
import type { TestingType } from '../stores'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps<{
  tabs: {
    title: string
    hash: TestingType
    colors: {
      text: {
        from: WindiColor
        to: WindiColor
      }
      icon: {
        fill: WindiColor
        stroke: WindiColor
      }
    }
  }[]
}>()

const $tabs = ref<HTMLAnchorElement[]>()
const $wrapper = ref<HTMLDivElement>()
const mounted = useMounted()
const activeButtonPosition = ref<{ width: string; transform: string }>({
  width: '0',
  transform: 'translateX(0)',
})

watch(testingType, (type) => {
  if (mounted.value) {
    document.location.hash = type
  }
  setUnderlinePosition(type)
})

onMounted(() => {
  setUnderlinePosition(testingType.value)
  setTestingTypeFromHash()
})

function setUnderlinePosition(type: TestingType) {
  const activeTab = $tabs.value?.find((tab) => tab.href.split('#')[1] === type)
  if (!activeTab || !$wrapper.value) {
    return
  }

  const wrapperWidth = $wrapper.value.offsetWidth
  const leftFromBox = activeTab.offsetLeft || 0

  activeButtonPosition.value = {
    width: `${Math.ceil(activeTab.offsetWidth || 0)}px`,
    transform: `translateX(${leftFromBox - wrapperWidth / 2}px)`,
  }
}

const activeButtonUnderlineColor = computed(() => {
  const activeTab = props.tabs.find((tab) => tab.hash === testingType.value)
  return [`from-${activeTab?.colors.text.from}`, `to-${activeTab?.colors.text.to}`]
})

/* <windi-keep 
    class="from-teal-600 to-jade-400 from-indigo-500 to-purple-500" 
    fill-color="jade-300" 
    stroke-color="teal-500"
    fill-color="indigo-300" 
    stroke-color="purple-500"
  /> */
</script>

<template>
  <div ref="$wrapper" class="relative flex items-center justify-center gap-[12px] sm:gap-[32px]" role="tablist">
    <a
      v-for="{ hash, title, colors } in props.tabs"
      :key="hash"
      ref="$tabs"
      role="tab"
      :href="`#${hash}`"
      class="group relative flex flex-col items-center justify-center gap-x-[16px] gap-y-[8px] px-[16px] pb-[16px] sm:h-[64px] sm:flex-row sm:justify-start"
      @click="testingType = hash"
    >
      <template v-if="testingType === hash">
        <TestingTypeIcon
          :name="hash"
          :fill-color="colors.icon.fill"
          :stroke-color="colors.icon.stroke"
          size="24"
          :solid="true"
        />
      </template>
      <template v-else>
        <TestingTypeIcon
          v-for="n in 2"
          :key="n"
          :name="hash"
          fill-color="gray-100"
          stroke-color="gray-500"
          size="24"
          class="transform transition-opacity duration-700"
          :class="n === 2 ? 'absolute top-0 opacity-0 group-hover:opacity-100 sm:top-auto' : 'group-hover:opacity-0'"
          :solid="n === 1"
        />
      </template>
      <span
        key="label"
        class="whitespace-nowrap text-[14px] font-medium leading-[20px] sm:text-[16px] sm:leading-[24px]"
        :class="
          testingType === hash
            ? `bg-gradient-to-r bg-clip-text text-transparent from-${colors.text.from} to-${colors.text.to}`
            : 'text-gray-700'
        "
      >
        {{ title }}
      </span>
      <div
        v-if="!mounted && testingType === hash"
        class="absolute bottom-[-2px] left-0 z-10 block h-[4px] w-full rounded rounded-full bg-gradient-to-r"
        :class="[activeButtonUnderlineColor, mounted ? 'transition transition-transform duration-300' : undefined]"
      >
        &nbsp;
      </div>
    </a>
    <div
      class="absolute bottom-0 left-[10%] right-[10%] block h-[1px] translate-y-[0.5px] transform rounded-full bg-gradient-to-r from-transparent via-gray-100 to-transparent"
    ></div>
    <div
      class="absolute bottom-[-2px] left-[50%] block h-[4px] w-full rounded rounded-full bg-gradient-to-r"
      :class="[activeButtonUnderlineColor, mounted ? 'transition transition-transform duration-300' : undefined]"
      :style="activeButtonPosition"
    >
      &nbsp;
    </div>
  </div>
</template>
