import { h, type Component } from 'vue'
import {
  IconTestingTypeE2E,
  IconTestingTypeComponent,
  IconTestingTypeE2ESolid,
  IconTestingTypeComponentSolid,
} from '@cypress-design/vue-icon'

export const IconMapSolid: Record<'e2e' | 'component', Component> = {
  e2e: IconTestingTypeE2ESolid,
  component: IconTestingTypeComponentSolid,
}

export const IconMap: Record<'e2e' | 'component', Component> = {
  e2e: IconTestingTypeE2E,
  component: IconTestingTypeComponent,
}

interface IconProps {
  name: keyof typeof IconMap
  key?: number
  solid?: boolean
}

export default ({ name, solid, ...parameters }: IconProps) => {
  return h(solid ? IconMapSolid[name] : (IconMap[name] as any), parameters)
}
